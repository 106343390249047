import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {Router, RouterModule} from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {UserProfileResetPassword, UserService} from '../../../../@backend';
import {firstValueFrom} from "rxjs";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {PaginatorModule} from "primeng/paginator";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {AuthenticationService} from "../../services/authentication.service";


@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, RouterModule, MatDialogModule, PaginatorModule, ReactiveFormsModule, FormsModule, MatInputModule],
  selector: 'app-user-change-password-modal',
  template: `
    <div class="flex flex-col p-4">
          <form [formGroup]="changePasswordGroup" (ngSubmit)="save()">
            <h1 mat-dialog-title class="text-center">Změna hesla</h1>
            <div mat-dialog-content class="flex flex-col place-content-center items-stretch gap-2">
                <mat-form-field>
                  <mat-label>Aktuální heslo</mat-label>
                  <input matInput type="password" formControlName="currentPassword">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Nové heslo</mat-label>
                  <input matInput type="password" formControlName="newPassword">
                  @if(changePasswordGroup.controls.newPassword.errors?.incorrect) {
                    <mat-error>Hesla se neshodují</mat-error>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Nové heslo znovu</mat-label>
                  <input matInput type="password" formControlName="newPasswordRepeat">
                  @if(changePasswordGroup.controls.newPasswordRepeat.errors?.incorrect) {
                    <mat-error>Hesla se neshodují</mat-error>
                  }
                </mat-form-field>
          </div>
        <div mat-dialog-actions class="flex flex-row items-center place-items-center place-content-center">
          <button mat-flat-button mat-dialog-close>Zavřít</button>
          <button mat-flat-button color="primary" type="submit" [disabled]="!changePasswordGroup.valid">Uložit</button>
        </div>
        </form>
    </div>
  `,
})
export class UserChangeMyPasswordModalComponent implements OnInit {

  changePasswordGroup = new FormGroup({
    currentPassword: new FormControl(null, Validators.required),
    newPassword: new FormControl(null, [Validators.required, Validators.minLength(7)]),
    newPasswordRepeat: new FormControl(null, [Validators.required, Validators.minLength(7)]),
  });
  constructor(private userService: UserService, private authService: AuthenticationService, private router: Router) {
  }
   async ngOnInit(): Promise<void> {
  }

  async save() {

    const newPassword = this.changePasswordGroup.value as UserProfileResetPassword;

    if(newPassword.newPassword !== newPassword.newPasswordRepeat) {
      this.changePasswordGroup.controls.newPassword.setErrors({incorrect: true});
      this.changePasswordGroup.controls.newPasswordRepeat.setErrors({incorrect: true});
      return;
    }

    try {
      const res = firstValueFrom(this.userService.userControllerResetMyPassword(newPassword));
      if(res) {
        this.authService.logout();
        window.location.href = '/';
      }
    } catch (e) {
      console.log(e);
    }


  }

}
