<div class="flex flex-row justify-between border-b border-b-slate-300 hover:bg-gray-100 p-2">
  <div class="flex flex-row items-center gap-3">
    <div class="flex flex-row items-center gap-2">
      <mat-icon svgIcon="mat:photo" color="primary"></mat-icon>
      <p-overlayPanel class="flex flex-col items-center" #op>
        <img [alt]="bidItem.item.name" class="block object-cover object-center cursor-pointer" width="200" height="200"
             [src]='bidItem.item.photo|photoSecured:"1":"/plain/rs:fill:200:200:1/g:no/":bidItem.item.shared | async' />
        <h3 class="font-bold text-base">{{bidItem.item.name}}</h3>
        <!--          <kodl-author class="font-bold" [authors]="pic?.authors"></kodl-author>-->
      </p-overlayPanel>

        <span class="label label-katalogove-cislo inline" matTooltip="Katalogové číslo">
          @if(bidItem.item.catalogNumber) {
            <span>{{bidItem.item.catalogNumber}}</span> /
            @if(auction.auctionNumber) {
              <span >{{auction.auctionNumber}}</span>
            }
          }@else{
            <span> - </span>
            }
        </span>
      <div class="flex flex-col">
        <div>
          <a routerLink="/dila/detail/{{bidItem.id}}" class="font-light text-blue-400 hover:underline" (mouseenter)="op.toggle($event)" (mouseleave)="op.hide()">
          <span>{{bidItem.item.name}}</span>
          </a>
        </div>
        <div class="text-sm"><kodl-author [authors]="bidItem.item.authors"></kodl-author></div>
      </div>
    </div>
  </div>
  <div class="flex flex-row items-center gap-2">
    @if(isWinner){
      <mat-icon color="primary" [matTooltip]="'Klient dílo vydražil za: ' + (bidItem.item.auctionSalePrice | currency : 'CZK' : 'symbol' : '1.0-0')" svgIcon="mat:emoji_events"></mat-icon>
    }
    @if(true) {
      <button mat-icon-button (click)="onRemoveBidItem()">
        <mat-icon color="warn" svgIcon="mat:delete"></mat-icon>
      </button>
    }
  </div>
</div>
