import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AuctionTerm, ClientBiddedItemWithRelations, ClientService, ModxPicturecontractWithRelations
} from '../../../../@backend';
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialog} from "../confirm-dialog/confirm-dialog.component";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'canvas-client-biditem-card',
  templateUrl: './client-biditem-card.component.html'
})
export class ClientBiditemCardComponent {
  @Input() bidItem: ClientBiddedItemWithRelations;
  @Input() auction: AuctionTerm;
  @Input() isWinner: boolean;
  @Output() onRemoveBid = new EventEmitter<number>();

  constructor(private matDialog: MatDialog, private clientService: ClientService) { }

  onRemoveBidItem() {
    this.matDialog.open(ConfirmDialog, {
      data: {
        title: 'Opravdu chcete odstranit položku?',
        message: 'Opravdu chcete odstranit položku z aukce?',
        confirmText: 'Ano',
      }
    }).afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        const res = await firstValueFrom(this.clientService.clientControllerRemoveClientBiddedItem(this.bidItem.clientId, this.bidItem.id));
        this.onRemoveBid.emit(this.bidItem.id);
      }
    })

  }
}
