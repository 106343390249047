<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading">{{authService.currentUserValue.firstname}} {{authService.currentUserValue?.lastname}}</div>
    </div>
    <div class="flex flex-row gap-3">
      <button mat-icon-button matTooltip="Změna hesla" matTooltipPosition="below" type="button" (click)="changePassword()">
        <mat-icon class="notifications-header-icon" svgIcon="mat:password"></mat-icon>
      </button>

      <button mat-icon-button matTooltip="Zobrazit 2FA QR kód" matTooltipPosition="below" type="button" (click)="open2FAQRCode()">
        <mat-icon class="notifications-header-icon" svgIcon="mat:key"></mat-icon>
      </button>
<!--      <button mat-icon-button matTooltip="Nastavení" matTooltipPosition="below" type="button">-->
<!--        <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>-->
<!--      </button>-->
    </div>
  </div>

  <div class="dropdown-content">
    @for(item of items; track item.id) {
      <a (click)="close()"
         [routerLink]="item.route"
         class="notification flex items-center"
         matRipple>
        <mat-icon [svgIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon flex-none"></mat-icon>
        <div class="flex-auto">
          <div class="notification-label">{{ item.label }}</div>
          <div class="notification-description">{{ item.description }}</div>
        </div>
        <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
      </a>
    }
  </div>

  <div class="dropdown-footer flex items-center justify-between">
    <a (click)="logout()" [routerLink]="['/login']" color="primary" mat-button>Odhlásit</a>
  </div>
</div>
